<template>
  <div id="message">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />详情
        </van-col>
      </van-row>
      
    </div>

  
    <div class="message-container" >
      <div
        class="message-tuan"
        style="height:80vh;"
      >
        <van-row class="tuan-info">
          <van-col span="10">
            <div class="tuan-start-time text-left">2021.09.09</div>
            <div class="tuan-start-address text-left">中南百草园</div>
          </van-col>
          <van-col span="4">
            <div class="need-time">2天</div>
            <div class="tuan-items">出团详情</div>
          </van-col>
          <van-col span="10">
            <div class="tuan-end-time text-right">2021.09.09</div>
            <div class="tuan-end-address text-right">欢乐风暴</div>
          </van-col>
        </van-row>
        <div class="message-content">
          <div class="message-content-title">紧急事件</div>
          <van-row>
            <van-col span="12" class="message-content-name">
              安吉漂流城
            </van-col>
            <van-col span="12" class="message-content-date">
              2021.09.09 06:23:00
            </van-col>
          </van-row>
          <div class="message-content-detail">
            在安吉漂流城有两位乘客突然晕倒，需紧急送往医院进行救治，在安吉漂流城有两位乘客突然晕倒，需紧急送往医院进行救治
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tarbar from "../components/Tarbar.vue";
export default {
  name: "message",
  components: { Tarbar },
  data() {
    return {
      list: [],
      active: 0,
      loading: true,
      finished: false,
      refreshing: false,
      query: {
        page: 1,
      },
      tabs: [
        {
          id: 0,
          text: "出团通知",
        },
        {
          id: 1,
          text: "事件通知",
        },
        {
          id: 2,
          text: "系统通知",
        },
      ],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async get_message() {
      const res = await this.$apis.get_message(this.query);
      if (res.message.length == 25) {
        this.query.page++;
        // this.list.concat(res.data);

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      // this.list = res.message;
      for (var i = 0; i < res.message.length; i++) {
        this.list.push(res.message[i]);
      }
    },
    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_message();
    },
  },
  created() {
    // this.onRefresh();
  },
};
</script>

<style scoped>
#message {
  height: 100vh;
  /* background: #f3f3f3; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
  /* background: #6c94f6; */
  border-bottom-left-radius: 1rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.message-container {
  padding: 0.4rem;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 1.2rem;
}
.message-item {
  background: #ffffff;
  height: 1.5rem;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding: 0.3rem;
  position: relative;
  margin-bottom: 10px;
}
.message-item-title {
  position: absolute;
  top: 0.3rem;
  left: 0.4rem;
  display: inline;
  color: #333;
  font-size: 14px;
  font-weight: bold;
}
.message-item-body {
  font-size: 14px;
  color: #666666;
  position: absolute;
  top: 0.8rem;
  left: 0.4rem;
}
.message-item-date {
  font-size: 12px;
  color: #999999;
  position: absolute;
  top: 0.3rem;
  right: 0.4rem;
}
.tab-item {
  opacity: 0.47;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.tab-item-active {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.message-tuan {
  background: #fff;
  padding: 0.4rem;
  border-radius: 20px;
  position: relative;
  margin-bottom: 10px;
}
.tuan-info {
  /* margin-top: 1.6rem; */
}
.tuan-start-time,
.tuan-end-time {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.tuan-start-address,
.tuan-end-address {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.tuan-items {
  font-size: 12px;
  color: #436def;
  text-align: center;
}
.need-time {
  font-size: 12px;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.start-date {
  font-size: 12px;
  color: #666666;
  margin-top: 20px;
}
.tuan-ren-number {
  font-size: 12px;
  color: #666666;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.status-btn {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  background: rgba(173, 173, 173, 0.2);
  color: #c1c1c1;
  padding: 2px 0.2rem;
}

.issue-item {
  background: #ffffff;
  height: 1rem;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  line-height: 1rem;
  padding: 0 0.3rem;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
.issue-item-title {
  color: #333;
  font-size: 12px;
  font-weight: bold;
}
.issue-item-body {
  font-size: 14px;
  color: #666666;
  position: absolute;
  top: 0.8rem;
  left: 0.4rem;
}
.issue-item-date {
  font-size: 12px;
  color: #999999;
  text-align: right;
}
.dot {
  position: absolute;
  height: 8px;
  width: 8px;
  background: #f04134;
  border-radius: 50%;
  top: 0.2rem;
  right: 0.1rem;
}
.message-content {
  border-top: 1px dashed #ebedf0;
  margin-top: 20px;
}
.message-content-title {
  font-size: 14px;
  color: #333333;
  height: 0.8rem;
  line-height: 0.8rem;
  font-weight: bold;
}
.message-content-name {
  font-size: 14px;
  color: #333333;
  height: 0.5rem;
  line-height: 0.5rem;
  font-weight: bold;
}
.message-content-date {
  font-size: 12px;
  color: #666666;
  text-align: right;
}
.message-content-detail{
   font-size: 12px;
  color: #666666;
  text-align: justify;
}
.message-time {
  position: absolute;
  top: -0.4rem;
  left: 0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  width: 100%;
}
</style>
